<template>
  <div class="invoice-detail-component">
    <div class="detail-title">开票内容</div>
    <el-row :gutter="48">
      <el-col
        :span="12"
        v-for="(item, index) in dataset.itemsInfoVOS"
        :key="index"
      >
        <div class="label-span">{{ item.material }}</div>
        <div class="value-span">￥{{ item.detailAmount }}</div>
      </el-col>
    </el-row>
    <div class="detail-title">发票抬头</div>
    <el-row :gutter="48">
      <el-col :span="12">
        <div class="label-span">发票号码</div>
        <div class="value-span" :title="dataset.invoiceNo">
          {{ dataset.invoiceNo || "--" }}
        </div>
      </el-col>
      <el-col :span="12">
        <div class="label-span">抬头类型</div>
        <div class="value-span" :title="dataset.openInvoiceType">
          {{ dataset.openInvoiceType || "--" }}
        </div>
      </el-col>
      <el-col :span="12">
        <div class="label-span">名称</div>
        <div class="value-span" :title="dataset.buyerName">
          {{ dataset.buyerName || "--" }}
        </div>
      </el-col>
      <template v-if="dataset.openInvoiceType === '企业'">
        <el-col :span="12">
          <div class="label-span">税号</div>
          <div class="value-span" :title="dataset.buyerTaxNo">
            {{ dataset.buyerTaxNo || "--" }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="label-span">单位地址</div>
          <div class="value-span" :title="dataset.buyerAddress">
            {{ dataset.buyerAddress || "--" }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="label-span">电话号码</div>
          <div class="value-span" :title="dataset.buyerMobilePhone">
            {{ dataset.buyerMobilePhone }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="label-span">开户银行</div>
          <div class="value-span" :title="dataset.buyerAccountAddress">
            {{ dataset.buyerAccountAddress || "--" }}
          </div>
        </el-col>
        <el-col :span="12">
          <div class="label-span">银行账户</div>
          <div class="value-span" :title="dataset.buyerAccount">
            {{ dataset.buyerAccount || "--" }}
          </div>
        </el-col>
      </template>
      <el-col :span="24">
        <div class="pdf-link" @click="openPDFLink">发票链接</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "invoiceDetailComponent",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    openPDFLink() {
      if (this.dataset.pdfUrl) {
        window.open(this.dataset.pdfUrl, "_blank");
      } else {
        this.$message.warning("暂无发票地址");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.invoice-detail-component {
  .detail-title {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    padding-left: 10px;
    &::before {
      content: "";
      display: inline-block;
      width: 3px;
      background: #409eff;
      border-radius: 2px;
      height: 14px;
      position: absolute;
      left: 0;
      top: 13px;
    }
  }
  .label-span {
    font-size: 14px;
    color: #999;
    height: 24px;
    line-height: 24px;
  }
  .value-span {
    font-size: 14px;
    color: #000;
    height: 24px;
    line-height: 24px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .pdf-link {
    color: #409eff;
    cursor: pointer;
    margin-bottom: 10px;
  }
}
</style>
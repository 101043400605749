<template>
  <div class="app-container invoice-record-component">
    <div class="filter-line">
      <span>
        <el-button @click="getTableList" icon="el-icon-refresh-right">{{
          this.$t("commons.refresh")
        }}</el-button>
        <el-button type="primary" @click="exportHandler">{{
          this.$t("commons.export")
        }}</el-button>
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!-- 发票详情 -->
    <el-dialog
      :visible.sync="detailDialogShow"
      width="728px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>发票详情</span>
      </template>
      <invoiceDetail
        v-if="detailDialogShow"
        :dataset="invoiceDetails"
        @close="detailDialogShow = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import invoiceDetail from "./invoiceDetail.vue";
import {
  getInvoiceList,
  invoiceListExport,
} from "@/api/ruge/jwPark/invoiceRecord";
import { dateFormat } from "@/filters/index";

export default {
  name: "JWParkInvoiceRecord",
  components: {
    finalTable,
    invoiceDetail,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "createTime", label: "开票时间", width: "" },
          { prop: "billNo", label: "开票记录编号", width: "" },
          { prop: "buyerName", label: "发票抬头", width: "" },
          { prop: "totalAmount", label: "开票金额", width: "" },
          { prop: "openInvoiceType", label: "抬头类型", width: "" },
          { prop: "invoiceEntityType", label: "开票类型", width: "" },
          { prop: "invoiceNo", label: "发票号码", width: "" },
          { prop: "buyerMobilePhone", label: "手机号码", width: "" },
          { prop: "operation", label: "操作", width: "115" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          billNo: {
            type: "input",
            label: "开票记录编号",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入开票记录编号",
            prefixIcon: "el-icon-search",
          },
          buyerName: {
            type: "input",
            label: "发票抬头",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入发票抬头",
            prefixIcon: "el-icon-search",
          },
          invoiceEntityType: {
            type: "selector",
            label: "开票类型",
            actionType: "goSearch",
            value: "",
            placeholder: "请选择开票类型",
            optionList: [
              {
                label: "停车",
                value: "停车",
              },
              {
                label: "租赁",
                value: "租赁",
              },
              {
                label: "充电",
                value: "充电",
              },
              {
                label: "场地预约",
                value: "场地预约",
              },
            ],
          },
          invoiceNo: {
            type: "input",
            label: "发票号码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入发票号码",
            prefixIcon: "el-icon-search",
          },
          totalAmount: {
            type: "numberInput",
            label: "开票金额",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入开票金额",
            prefixIcon: "el-icon-search",
          },
          buyerMobilePhone: {
            type: "input",
            label: "手机号码",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入手机号码",
            prefixIcon: "el-icon-search",
          },
          // openInvoiceType: {
          //   type: "selector",
          //   label: "开票类型",
          //   actionType: "goSearch",
          //   value: "",
          //   placeholder: "请选择开票类型",
          //   optionList: [
          //     {
          //       label: "个人",
          //       value: "个人",
          //     },
          //     {
          //       label: "企业",
          //       value: "企业",
          //     },
          //   ],
          // },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          createTime: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "详情",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          createTime: {
            type: "dateRange",
            pickerType: "date",
            label: "开票时间",
            value: [],
          },
          billNo: {
            inline: true,
            value: "",
          },
          buyerName: {
            inline: true,
            value: "",
          },
          totalAmount: {
            inline: true,
            value: "",
          },
          // openInvoiceType: {
          //   inline: true,
          //   value: "",
          // },
          invoiceEntityType: {
            inline: true,
            value: "",
          },
          invoiceNo: {
            inline: true,
            value: "",
          },
          buyerMobilePhone: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      requestParams: {
        current: 1,
        rowCount: 10,
        buyerName: null,
        buyerMobilePhone: null,
        totalAmount: null,
        // openInvoiceType: null,
        billNo: null,
        startDate: null,
        endDate: null,
      },
      detailDialogShow: false,
      invoiceDetails: null,
      loadingFlag: false,
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    if (this.$route.query.goBackFlag) {
      const curSearch = sessionStorage.getItem("invoice_list_query_params");
      if (curSearch) {
        const tempObj = JSON.parse(curSearch);
        this.requestParams = { ...this.requestParams, ...tempObj };
        Object.keys(this.requestParams).forEach((item) => {
          if (this.dataset.searchLineConfig[item]) {
            this.dataset.searchLineConfig[item].value =
              this.requestParams[item];
          }
        });
        // 处理分页数据
        this.dataset.pageVO.current = tempObj.current;
        this.dataset.pageVO.rowCount = tempObj.rowCount;
      }
    } else {
      sessionStorage.removeItem("invoice_list_query_params");
    }
    this.getTableList();
  },
  methods: {
    tableEventHandler(datas) {
      console.log("datas", datas);
      if (datas.type === "goSearch") {
        this.requestParams.current = 1;
        this.dataset.pageVO.current = 1;
        this.dealTime(datas);
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.dataset.pageVO.current = datas.params.current.page;
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            // datas.row
            this.openDetailDialog(datas.row);
            break;
        }
      }
    },
    dealTime(datas) {
      if (datas.params.createTime && datas.params.createTime.length > 0) {
        this.requestParams.startDate = datas.params.createTime[0]
          ? dateFormat(datas.params.createTime[0], "YYYY-MM-DD HH:mm:ss")
          : null;
        this.requestParams.endDate = datas.params.createTime[1]
          ? dateFormat(
              new Date(datas.params.createTime[1]).getTime() +
                1000 * 60 * 60 * 24 -
                1,
              "YYYY-MM-DD HH:mm:ss"
            )
          : null;
        delete datas.params.createTime;
      }
      // else {
      //   this.requestParams.startDate = null;
      //   this.requestParams.endDate = null;
      // }
      return datas;
    },
    getTableList() {
      this.loadingFlag = true;
      getInvoiceList(this.requestParams)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .catch(() => {
          this.dataset.tableData = [];
          this.dataset.pageVO.total = 0;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    exportHandler() {
      invoiceListExport(this.requestParams).then((taskId) => {
        this.$message({
          type: "success",
          message: this.$t("message.operationSuccess"),
        });
        let exportObj = {
          taskId,
          taskName: "发票开具列表",
          taskStatus: 0,
          rootPath: "customerWXPath",
        };
        this.$store.dispatch("PushExportNotice", exportObj);
      });
    },
    openDetailDialog(rowData) {
      this.detailDialogShow = true;
      this.invoiceDetails = rowData;
    },
  },
};
</script>

<style lang="less" scoped>
.invoice-record-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }
  ::v-deep .el-dialog__body {
    padding-top: 0 !important;
    padding-bottom: 20px !important;
  }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "invoice-detail-component" },
    [
      _c("div", { staticClass: "detail-title" }, [_vm._v("开票内容")]),
      _c(
        "el-row",
        { attrs: { gutter: 48 } },
        _vm._l(_vm.dataset.itemsInfoVOS, function (item, index) {
          return _c("el-col", { key: index, attrs: { span: 12 } }, [
            _c("div", { staticClass: "label-span" }, [
              _vm._v(_vm._s(item.material)),
            ]),
            _c("div", { staticClass: "value-span" }, [
              _vm._v("￥" + _vm._s(item.detailAmount)),
            ]),
          ])
        }),
        1
      ),
      _c("div", { staticClass: "detail-title" }, [_vm._v("发票抬头")]),
      _c(
        "el-row",
        { attrs: { gutter: 48 } },
        [
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "label-span" }, [_vm._v("发票号码")]),
            _c(
              "div",
              {
                staticClass: "value-span",
                attrs: { title: _vm.dataset.invoiceNo },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.dataset.invoiceNo || "--") +
                    "\n      "
                ),
              ]
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "label-span" }, [_vm._v("抬头类型")]),
            _c(
              "div",
              {
                staticClass: "value-span",
                attrs: { title: _vm.dataset.openInvoiceType },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.dataset.openInvoiceType || "--") +
                    "\n      "
                ),
              ]
            ),
          ]),
          _c("el-col", { attrs: { span: 12 } }, [
            _c("div", { staticClass: "label-span" }, [_vm._v("名称")]),
            _c(
              "div",
              {
                staticClass: "value-span",
                attrs: { title: _vm.dataset.buyerName },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.dataset.buyerName || "--") +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm.dataset.openInvoiceType === "企业"
            ? [
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "label-span" }, [_vm._v("税号")]),
                  _c(
                    "div",
                    {
                      staticClass: "value-span",
                      attrs: { title: _vm.dataset.buyerTaxNo },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.dataset.buyerTaxNo || "--") +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "label-span" }, [
                    _vm._v("单位地址"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value-span",
                      attrs: { title: _vm.dataset.buyerAddress },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.dataset.buyerAddress || "--") +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "label-span" }, [
                    _vm._v("电话号码"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value-span",
                      attrs: { title: _vm.dataset.buyerMobilePhone },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.dataset.buyerMobilePhone) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "label-span" }, [
                    _vm._v("开户银行"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value-span",
                      attrs: { title: _vm.dataset.buyerAccountAddress },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.dataset.buyerAccountAddress || "--") +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
                _c("el-col", { attrs: { span: 12 } }, [
                  _c("div", { staticClass: "label-span" }, [
                    _vm._v("银行账户"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "value-span",
                      attrs: { title: _vm.dataset.buyerAccount },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.dataset.buyerAccount || "--") +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
              ]
            : _vm._e(),
          _c("el-col", { attrs: { span: 24 } }, [
            _c(
              "div",
              { staticClass: "pdf-link", on: { click: _vm.openPDFLink } },
              [_vm._v("发票链接")]
            ),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }